<template>
  <div class="text-center">
    <v-btn
      class="mx-2 ml-4"
      fab
      dark
      small
      color="primary"
      style="width: fit-content; height: fit-content; padding: 0.4rem"
      @click="open"
      v-show="!!callData"
    >
      <v-icon dark :size="40"> mdi-information </v-icon>
    </v-btn>
    <v-dialog v-model="showGluedDetailModal" max-width="600">
      <v-card>
        <v-progress-linear
          indeterminate
          color="cyan"
          v-if="turnLoading"
        ></v-progress-linear>
        <v-card-title
          class="headline grey lighten-2"
          style="display: flex; justify-content: space-between"
        >
          Datos extra {{ atended }}
          <v-btn icon dark @click="close" left style="color: black">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text
          style="
            min-height: 275px;
            display: flex;
            flex-direction: column;
            justify-content: center;
          "
        >
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel v-for="(item, i) in columns" :key="i">
              <v-expansion-panel-header>
                {{ item.title }}
              </v-expansion-panel-header>
              <v-expansion-panel-content class="Container__column">
                <table>
                  <thead>
                    <tr>
                      <th v-for="(column, k) in table_columns" :key="k">
                        {{ column.text | capitalize }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(turn, k) in item.columns_table" :key="k">
                      <!-- Data first column -->
                      <td
                        v-if="
                          (item.key == 'customer' &&
                            !editClient &&
                            Object.keys(customer).length > 0) ||
                          item.key == 'turn'
                        "
                      >
                        {{ turn.text }}
                      </td>
                      <!-- IF IS TURN (Information - second column) -->
                      <td v-if="item.key == 'turn'">
                        <span
                          v-if="
                            turnData[turn.key] &&
                            turnData[turn.key][turn.sub_key]
                          "
                        >
                          {{ turnData[turn.key][turn.sub_key] }}
                        </span>
                        <span v-else>
                          <span v-if="turn.key == 'totalAttentionTime'">
                            {{ showPanelSeconds | secondsToHours }}
                          </span>
                          <span v-else>
                            {{ turnData[turn.key] }}
                          </span>
                        </span>
                      </td>
                      <!-- IF IS CUSTOMER (Information - second column) -->
                      <td
                        v-if="
                          item.key == 'customer' &&
                          !editClient &&
                          Object.keys(customer).length > 0
                        "
                        :class="turn.key === 'dni' ? 'DNI' : ''"
                      >
                        {{ customer[turn.key] }}
                        <v-icon
                          v-if="turn.key === 'dni'"
                          size="20"
                          :style="
                            colorGreenCopied
                              ? 'color: green; margin-left: 5px;'
                              : 'color: black; margin-left: 5px;'
                          "
                          class="CopyIcon"
                          @click="handleCopy"
                          >mdi-content-copy</v-icon
                        >
                        <!-- <span
                          class="font9-dk"
                          v-if="turn.key === 'dni' && colorGreenCopied"
                        >
                          Texto copiado al portapapeles
                        </span> -->
                      </td>
                    </tr>
                  </tbody>
                </table>

                <!-- CUSTOMER FORM -->
                <span v-if="item.key == 'customer' && editClient">
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <!-- <v-text-field
                      v-model="customerDataForm.cedulaIdentidad"
                      :counter="10"
                      :rules="nameRules"
                      label="Cédula de Identidad"
                      required
                    ></v-text-field> -->

                    <v-text-field
                      v-model="customerDataForm.dni"
                      :rules="nameRules"
                      label="DNI"
                      required
                    ></v-text-field>

                    <v-text-field
                      v-model="customerDataForm.firstName"
                      :rules="nameRules"
                      label="Nombre"
                      required
                    ></v-text-field>

                    <v-text-field
                      v-model="customerDataForm.lastName"
                      :rules="nameRules"
                      label="Apellido"
                      required
                    ></v-text-field>

                    <v-text-field
                      v-model="customerDataForm.razon"
                      :rules="nameRules"
                      label="Razón Social"
                      required
                    ></v-text-field>

                    <v-select
                      v-model="customerDataForm.customerType"
                      :items="customerTypes"
                      item-value="id"
                      item-text="name"
                      :rules="[(v) => !!v || 'Campo es requerido']"
                      label="Tipo de cliente"
                      required
                    ></v-select>

                    <v-text-field
                      v-model="customerDataForm.email"
                      :rules="emailRules"
                      label="Email"
                      required
                    ></v-text-field>

                    <v-text-field
                      v-model="customerDataForm.phone"
                      :rules="nameRules"
                      label="Teléfono"
                      required
                    ></v-text-field>

                    <v-text-field
                      v-model="customerDataForm.phoneType"
                      :rules="nameRules"
                      label="Tipo de Teléfono	"
                      required
                    ></v-text-field>

                    <v-text-field
                      v-model="customerDataForm.cuil"
                      :rules="nameRules"
                      label="CUIL"
                      required
                    ></v-text-field>

                    <v-text-field
                      v-model="customerDataForm.civic"
                      :rules="nameRules"
                      label="Libreta Cívica	"
                      required
                    ></v-text-field>

                    <v-text-field
                      v-model="customerDataForm.librete"
                      :rules="nameRules"
                      label="Libreta de Enrolamiento	"
                      required
                    ></v-text-field>

                    <v-text-field
                      v-model="customerDataForm.passport"
                      :rules="nameRules"
                      label="Pasaporte"
                      required
                    ></v-text-field>

                    <v-text-field
                      v-model="customerDataForm.cedule"
                      :rules="nameRules"
                      label="Cédula Diplomática"
                      required
                    ></v-text-field>

                    <v-text-field
                      v-model="customerDataForm.companyPhone"
                      :rules="nameRules"
                      label="Compañía telefónica"
                      required
                    ></v-text-field>
                  </v-form>
                </span>

                <!-- Search Customer -->
                <span
                  v-if="
                    item.key == 'customer' && Object.keys(customer).length < 1
                  "
                >
                  <div v-if="!assignCustomer">
                    No se encuentra un cliente asociado a este turno, ¿desea
                    asignar un cliente?
                  </div>

                  <v-form
                    v-else
                    ref="formSearchCustomer"
                    v-model="valid"
                    lazy-validation
                  >
                    <v-text-field
                      v-model="documentCustomer"
                      :counter="10"
                      :rules="nameRules"
                      label="Buscar cliente por cédula"
                      required
                    ></v-text-field>
                  </v-form>
                </span>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>

        <v-card-actions class="modal-footer">
          <v-btn
            v-if="!editClient && Object.keys(customer).length > 0"
            color="sucess"
            class="btn-green"
            @click="setEditClient"
          >
            Editar Cliente
          </v-btn>
          <!-- <v-btn
            v-if="!editClient && Object.keys(customer).length > 0"
            color="error"
            class="btn-green"
            @click="removeCutomer"
          >
            Designar Cliente
          </v-btn> -->
          <v-btn
            v-if="editClient && Object.keys(customer).length > 0"
            color="error"
            class="btn-green"
            @click="saveCustomer"
            :loading="turnLoading"
          >
            Guardar
          </v-btn>

          <!-- REMOVE AND ASIGN CUSTOMER -->
          <v-btn
            v-if="
              !editClient && Object.keys(customer).length < 1 && !assignCustomer
            "
            class="btn-green"
            @click="assignCustomer = !assignCustomer"
            :loading="turnLoading"
          >
            Asignar Cliente
          </v-btn>
          <v-btn
            v-if="
              !editClient && Object.keys(customer).length < 1 && assignCustomer
            "
            class="btn-green"
            @click="searchCustomer"
            :loading="turnLoading"
          >
            Buscar cliente
          </v-btn>
          <!-- ------ -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";

export default {
  name: "callDetailData",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    workstationid: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      colorGreenCopied: false,
      showGluedDetailModal: false,
      sortKey: "",
      searchQuery: "",
      columns: [
        {
          title: "Turno de atención",
          key: "turn",
          columns_table: [
            { text: "ID de turno", key: "id" },
            { text: "Trámite", key: "queue", sub_key: "name" },
            { text: "Sala de espera", key: "waitingRoom", sub_key: "name" },
            { text: "Tiempo de atención", key: "totalAttentionTime" },
          ],
        },
        {
          title: "Cliente",
          key: "customer",
          columns_table: [
            // { text: "Cédula de Identidad", key: "cedulaIdentidad" },
            { text: "DNI", key: "dni" },
            { text: "CUIT", key: "cuit" },
            { text: "Nombre", key: "firstName" },
            { text: "Apellido", key: "lastName" },
            { text: "Tipo de cliente", key: "firstName" },
            { text: "Teléfono", key: "phone" },
            { text: "CUIL", key: "cuil" },
          ],
        },
      ],
      panel: [0, 1],

      // table
      table_columns: [{ text: "Datos" }, { text: "Información" }],

      // counters
      showPanelSeconds: 0,
      editClient: false,

      // formulario
      valid: true,
      customerDataForm: {},
      nameRules: [
        (v) => !!v || "Campo es requerido",
        (v) =>
          (v && v.length <= 10) || "El campo debe tener menos de 20 carácteres",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail es requerido",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],

      // search customer form
      documentCustomer: "",
      assignCustomer: false,
    };
  },
  computed: {
    ...mapState({
      customer: (state) => state.customerSilver.customerData,
      turnLoading: (state) => state.customerSilver.loading,
      loggedUser: (state) => state.loggedUserSilver.loggedUserData,
      turnData: (state) => state.workstationTurnSilver.turn,
      callData: (state) => state.loggedUserSilver.callData,
      pollingData: (state) => state.pollingWorkplaceSilver.polling,
      customerTypes: (state) => state.customerTypesSilver.types,
      typesByCompnay: (state) => state.typesByCompanySilver.data,
    }),
    atended() {
      return this.callData
        ? `${this.callData?.letter}${this.callData?.number}`
        : "-";
    },
  },
  methods: {
    ...mapActions([
      "fetchCustomerDetails",
      "resetCustomerData",
      "sendUpdateCustomers",
      "getWorkstationTurn",
      "fetchCustomerTypes",
      "sendRemoveCustomerFromTurn",
      "sendFindAndAssignCustomer",
    ]),
    handleCopy() {
      let copyText = document.querySelector(".DNI");
      let timer = null;

      clearTimeout(timer);

      /* Copy the text inside the text field */
      navigator.clipboard.writeText(copyText.innerText);

      /* Alert the copied text */
      this.colorGreenCopied = true;
      timer = setTimeout(() => {
        this.colorGreenCopied = false;
        clearTimeout(timer);
      }, 2000);
      console.log("Copied the text: " + copyText.innerText);
    },
    open() {
      this.option = null;
      this.editClient = false;
      if (this.callData && this.callData.id) {
        this.fetchCustomerDetails(parseFloat(this.callData.id)).then(
          this.getWorkstationTurn(parseFloat(this.callData.id)).then(
            this.fetchCustomerTypes().then((this.showGluedDetailModal = true))
          )
        );
      }
    },
    async close() {
      this.showGluedDetailModal = false;
      this.documentCustomer = "";
      this.assignCustomer = false;
      this.editClient = false;
    },
    setEditClient() {
      this.customerDataForm = this.customer;
      this.editClient = true;
    },
    async saveCustomer() {
      await this.sendUpdateCustomers(this.customerDataForm);
      this.close();
    },
    async removeCutomer() {
      return;
      // let data = {
      //   cedulaIdentidad: this.customer.cedulaIdentidad,
      //   customerType: this.typesByCompnay.data,
      //   dni: this.customer.dni,
      //   firstName: this.customer.firstName,
      //   id: this.customer.id,
      //   lastName: this.customer.lastName,
      //   phone: this.customer.phone,
      // };

      // await this.sendRemoveCustomerFromTurn(data);
      // this.open();
    },
    async searchCustomer() {
      await this.fetchCustomerTypes();

      let data = {};
      // Toca revisar esta parte para sacar UUIDType y company
      // data.customersUuid = this.typesByCompnay;
      data.customersUuid = [
        {
          company: { id: 191 },
          uuidType: "CEDULA_IDENTIDAD",
          uuidValue: this.documentCustomer,
        },
      ];
      // ---
      data.cedulaIdentidad = this.documentCustomer;

      await this.sendFindAndAssignCustomer(data);
      this.open();
    },
  },
  filters: {
    capitalize: function (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
  watch: {
    showGluedDetailModal: function (val) {
      if (!val) {
        this.resetCustomerData();
      }
    },

    "pollingData.srvTime"() {
      if (this.callData) {
        var initTimeStamp = moment(String(this.callData.initTimeStamp));
        let now = moment();
        let seconds = now.diff(initTimeStamp, "seconds");
        this.showPanelSeconds = seconds;
      }
    },

    // "loggedUser.worker"(worker) {
    //   alert('deberia abrir el modal')
    //   if (worker.actualTurn && !this.showGluedDetailModal) {
    //     this.open();
    //   }
    // },
  },
};
</script>
<style>
table {
  width: 100%;
  max-width: 100%;
  text-align: center;
}

th {
  font-weight: 600;
  height: 50px;
}
.modal-footer {
  padding: 5px !important;
  border-top: 1px solid #e0e0e0;
  display: flex;
  justify-content: flex-end;
  /* flex-wrap: wrap; */
}

.Container__column {
  min-height: 150px;
  max-height: 150px;
  overflow: auto;
  max-width: 100%;
  overflow: auto;
}

.Container__column::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  /* display: none; */
}

.Container__column::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.Container__column::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.Container__column::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

.font9-dk {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: italic;
  color: green;
}

.Container__column::-webkit-scrollbar-track {
  /* background: #e1e1e1; */
  border-radius: 4px;
}

.Container__column:hover .Container__column::-webkit-scrollbar {
  display: initial;
}

.CopyIcon {
  transition: 1s all ease-in-out;
}

.CopyIcon:active,
.CopyIcon:focus {
  transform: scale(1.1);
  color: green;
}
</style>
